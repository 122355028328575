import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';

import { WEBSITE_DOMAIN } from '@murdoughsolutions/cms-common';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch(environment.domain_url).then(res => res.json()).then((data) => {
  platformBrowser([{ provide: WEBSITE_DOMAIN, useValue: data }]).bootstrapModule(AppModule);

}).catch(err => {
  console.error(err);
});


