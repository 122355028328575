import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CDNService, PostCollectionModel } from '@murdoughsolutions/cms-common';
import { Observable } from 'rxjs';

@Injectable()
export class PostCollectionResolver  {

    constructor(private cdn: CDNService)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<PostCollectionModel> {
        return this.cdn.GetPostCollection(route.data.post_collection_id);
    }
}
