<ngb-carousel *ngIf="media_collection" [wrap]="true">
  <ng-template ngbSlide *ngFor="let item of media_collection.items | orderBy: 'position'">
    <a *ngIf="item.page" [routerLink]="item.page.route">  
        <img [src]="cdn.getAssetUrl(item.asset)" [alt]="item.name" class="d-block w-100" />
    </a>
    <img *ngIf="!item.page" [src]="cdn.getAssetUrl(item.asset)" [alt]="item.name">
    <div class="carousel-caption">
      <h3>{{ item.name }}</h3>
    </div>
  </ng-template>
</ngb-carousel>