<cms-loading-overlay></cms-loading-overlay>
<header>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <a [routerLink]="['/']" class="logo"></a>
            </div>
            <div class="col-sm-6">
            </div>
        </div>
    </div>
</header>
<cms-menu id="c632bfd7-83d1-4020-94d0-9016edbf261f" [component_resolver]="component_resolver"></cms-menu>
<router-outlet></router-outlet>
<footer class="mt-2">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <h2>Navigation</h2>
                <cms-menu id="86fd7707-99b1-41fa-b8e4-fa3f3f596cc8" [component_resolver]="component_resolver"></cms-menu>
            </div>
            <div class="col-sm-6">
                <h2>Contact Us</h2>
                
                <cms-form id="137a0a65-ec8d-48b3-b359-331eee92851f"></cms-form>
            </div>
        </div>
        <hr/>
        <p>Copyright &copy;2023 Murdough Solutions LLC. All Rights Reserved.</p>
    </div>
</footer>