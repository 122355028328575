import { CustomCarousel } from './carousel/carousel';
import { CustomContactForm } from './contact-form/contact-form';
import { CustomContactPage } from './contact-page/contact-page';
import { CustomEMailFormField } from './e-mail-form-field/e-mail-form-field';
import { CustomFooterMenu } from './footer-menu/footer-menu';
import { CustomHome } from './home/home';
import { CustomHoneypot } from './honeypot/honeypot';
import { CustomMainMenu } from './main-menu/main-menu';
import { CustomPage } from './page/page';
import { CustomTextAreaFormField } from './text-area-form-field/text-area-form-field';
import { CustomTextFormField } from './text-form-field/text-form-field';

import { Type } from '@angular/core';

export function component_resolver(template_id: string): Type<any> | undefined {
    switch (template_id) {
        case 'cbdce627-de4d-4935-ba51-42308282cc68': return CustomMainMenu
        case 'c49bd66a-4398-4d3b-8487-1dfe0c20fde9': return CustomCarousel
        case '13d6acbf-903e-4910-9fea-0ac3c4713cde': return CustomTextFormField
        case '043a033e-2217-48a0-8f9f-c109148b7de4': return CustomEMailFormField
        case '36badf18-129c-4e22-9bec-2e5351b47866': return CustomContactPage
        case 'a5da1ca3-4ddc-4122-9524-49a0c859b751': return CustomHome
        case '85e15612-ffe9-427c-b844-47c89e38dbd9': return CustomPage
        case 'dad005bc-0392-4d57-8f64-0d7663fca0d4': return CustomContactForm
        case 'd2f17db5-48ec-4270-87a5-0adaac360937': return CustomTextAreaFormField
        case 'be766104-dd0a-4e46-8dd8-87c98be56d1b': return CustomFooterMenu
        case 'dab1ca28-e041-4487-9057-bc641e00fdce': return CustomHoneypot
    }
    return undefined
}