import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FORM_COMPLETION_FIELD_MODEL } from '@murdoughsolutions/cms-client';
import { FormCompletionFieldModel } from '@murdoughsolutions/cms-common';

@Component({
  selector: 'CustomTextFormField',
  templateUrl: './text-form-field.html',
  styleUrls: ['./text-form-field.scss']
})
export class CustomTextFormField  {

    constructor(
        @Inject(FORM_COMPLETION_FIELD_MODEL) public readonly form_field: FormCompletionFieldModel,
        public readonly control: FormControl
    ) {
      
    }

    public get isInvalid(): boolean {
        return this.control.touched && this.control.errors != null;
    }
}
