<form *ngIf="!form_completion" [formGroup]="form_group" (ngSubmit)="submit()">
    <cms-form-field *ngFor="let field of form.fields | orderBy: 'position'" [form_field]="field"  [formControlName]="field.form_field_id"></cms-form-field>
    <div class="form-group row">
        <div class="col-sm-9 offset-sm-3">
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </div>
    <div *ngIf="state == FormState.Invalid" class="alert alert-danger" role="alert">
        Please complete all required fields.
    </div>
    <div *ngIf="state == FormState.Error" class="alert alert-danger" role="alert">
        {{ error_message }}
    </div>
</form>
<div *ngIf="state == FormState.Success" class="alert alert-success" role="alert">
    Form Submitted!
</div>