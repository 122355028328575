import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { CDNService, MetaModel, PageModel, PostCategoryModel, PostCollectionModel, PostModel, ProductCategoryModel, ProductCollectionModel, ProductModel, ProductVariantPriceModel, WEBSITE_DOMAIN, WebsiteDomainModel } from '@murdoughsolutions/cms-common';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { GoogleTagManagerService } from './google-tag-manager.service';

interface RouteData {
  page_model: PageModel | undefined;
  post_collection_model: PostCollectionModel | undefined;
  post_category_model: PostCategoryModel | undefined;
  post_model: PostModel | undefined;
  product_collection_model: ProductCollectionModel | undefined;
  product_category_model: ProductCategoryModel | undefined;
  product_model: ProductModel | undefined;
}

interface RouteBreadcrumb {
  name: string;
  route: string[];
}



@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private readonly router: Router,
    @Inject(WEBSITE_DOMAIN) private readonly domain: WebsiteDomainModel,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly title_service: Title,
    private readonly meta_service: Meta,
    private readonly gtag: GoogleTagManagerService,
    private readonly cdn: CDNService
  ) {
  }

  private readonly volatile_schema_class_name = 'volatile-schema';

  public Initialize() {
    this.set_favicon();
    this.insert_website_schema(this.domain);
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart || e instanceof ActivationEnd)
    ).subscribe(e => {
      if (e instanceof NavigationStart) {
        this.remove_schemas();
      }

      if (e instanceof ActivationEnd) {
        const data: RouteData = e.snapshot.data as RouteData;
        const meta_content = data.page_model ?? data.post_model ?? data.post_category_model ?? data.post_collection_model ?? data.product_model ?? data.product_category_model ?? data.product_collection_model;

        // console.log(meta_content)

        if (meta_content) {
          this.updateContent(meta_content);
        }

        if (data.page_model) {
          this.setPath(data.page_model.route);

          const breadcrumbs = this.add_page_breadcrumb(data.page_model, []).reverse();

          this.InsertSchema({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadcrumbs.map((breadcrumb, index) => ({
              '@type': 'ListItem',
              position: index + 1,
              name: breadcrumb.name,
              item: this.buildUrl(breadcrumb.route)
            }))
          });
        }

        if (data.post_model) {
          this.setPath(data.post_model.route);
        }
        else if (data.post_category_model) {
          this.setPath(data.post_category_model.route);
        }
        else if (data.post_collection_model) {
          this.setPath(data.post_collection_model.route);
        }

        window.scrollTo(0, 0);
      }
    });
  }

  public buildUrl(route: string[]): string {
    return `https://${this.domain.cname}${route.filter(f => f != '/').join('/')}`;
  }

  private remove_schemas(): void {
    const elements = Array.from(this.document.head.getElementsByClassName(this.volatile_schema_class_name));
    for (const element of elements) {
      this.document.head.removeChild(element);
    }
  }

  public InsertSchema(schema: Record<string, any>, is_volatile = true): void {
    const script = this.document.createElement('script');
    if (is_volatile) {
      script.setAttribute('class', this.volatile_schema_class_name);
    }
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schema);
    this.document.head.appendChild(script);
  }

  private insert_website_schema(domain: WebsiteDomainModel) {
    const schema = {
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      name: domain.website.name,
      url: `https://${domain.cname}`
    };
    this.InsertSchema(schema, false);
  }

  private add_page_breadcrumb(page: PageModel, breadcrumbs: RouteBreadcrumb[]): RouteBreadcrumb[] {
    breadcrumbs.push({
      name: page.name,
      route: page.route
    });

    if (page.parent_page_id) {
      const parent_page = this.domain.website.pages.find(f => f.page_id == page.parent_page_id);
      if (parent_page) {
        this.add_page_breadcrumb(parent_page, breadcrumbs);
      }
    }

    return breadcrumbs;
  }

  private setPath(route: string[]) {
    const url = this.buildUrl(route);
    if (this.document) {
      const canonical = this.document.head.querySelector('link[rel=\'canonical\']') as HTMLLinkElement || this.document.head.appendChild(this.document.createElement('link'));
      canonical.rel = 'canonical';
      canonical.href = url;
    }

    this.updateMetaTags([
      { property: 'og:url', content: url }
    ]);
  }

  private updateContent(content: MetaModel): void {
    const title = `${content.meta_title ? content.meta_title : content.name} - ${this.domain.website.name}`;
    const meta: Array<MetaDefinition> = [
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: title },
      { name: 'description', content: content.meta_description ?? '' },
      { property: 'og:description', content: content.meta_description ?? '' },
      { name: 'keywords', content: content.meta_keywords ?? '' }
    ];

    this.updateMetaTags(meta);

    this.title_service.setTitle(title);
  }

  public updateMetaTags(meta: Array<MetaDefinition>) {
    for (const m of meta) {
      const selector = m.property ? 'property=\'' + m.property + '\'' : 'name=\'' + m.name + '\'';
      if (this.meta_service.getTag(selector)) {
        this.meta_service.updateTag(m);
      }
      else {
        this.meta_service.addTag(m);
      }
    }
  }

  private set_favicon(): void {
    if (this.domain.website.favicon_asset) {
      const url = this.cdn.getAssetUrl(this.domain.website.favicon_asset);

      if (this.document) {
        const link = this.document.head.querySelector('link[rel=\'icon\']') as HTMLLinkElement || this.document.head.appendChild(this.document.createElement('link'));
        link.rel = 'icon';
        link.type = 'image/x-icon';
        link.href = url;
      }

      this.meta_service.addTag({
        property: 'og:image',
        content: url
      });
    }
  }

  public addPriceSchema(schema: { [name: string]: string | number | undefined }, price: ProductVariantPriceModel): { [name: string]: string | number | undefined } {
    return {
      ...schema,
      price: price.price,
      priceCurrency: this.domain.website.countries.find(f => f.country_id == price.country_id)?.currency_code
    };
  }


}
