<div class="container" *ngIf="page$ | async as page">
    <h1>{{ page.name }}</h1>
    
    <div class="row">
        <div class="col-sm-6">
            <cms-template-section id="e1679a9d-4f85-4e3c-8ab0-9aaa1186c250"></cms-template-section>
        </div>
        <div class="col-sm-6">
            <cms-form id="137a0a65-ec8d-48b3-b359-331eee92851f"></cms-form>
        </div>
    </div>
    
</div>