import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CDNService, ContentModel, PageModel } from '@murdoughsolutions/cms-common';
import { map } from 'rxjs/operators';

@Component({
    selector: 'CustomPage',
    templateUrl: './page.html',
    styleUrls: ['./page.scss']
})
export class CustomPage {

    constructor(
        private readonly route: ActivatedRoute,
        public readonly router: Router,
        public readonly cdn: CDNService
    ) {

    }

    public readonly page$ = this.route.data.pipe(
        map(({ page_model }) => page_model as PageModel)
    )

    public readonly content$ = this.route.data.pipe(
        map(({ content_model }) => content_model as ContentModel)
    )

    scroll(el: HTMLElement): void {
        el.scrollIntoView({ behavior: 'smooth' });
    }
}
