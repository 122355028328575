import { Component, Inject } from '@angular/core';
import { MEDIA_COLLECTION_MODEL } from '@murdoughsolutions/cms-client';
import { CDNService, MediaCollectionModel } from '@murdoughsolutions/cms-common';

@Component({
  selector: 'CustomCarousel',
  templateUrl: './carousel.html',
  styleUrls: ['./carousel.scss']
})
export class CustomCarousel {
    
  constructor(
      @Inject(MEDIA_COLLECTION_MODEL) public readonly media_collection: MediaCollectionModel, 
      public readonly cdn: CDNService
  ) {
      
  }

}
