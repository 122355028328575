<div class="container clearfix" *ngIf="page$ | async as page">
    <h1>{{ page.name }}</h1>
    <div class="row" *ngIf="content$ | async as content">
        <ng-template #singleCol>
            <div class="col-sm-12">
                <cms-template-section id="f047bc36-d751-4554-8cf2-f2a6633b8324"></cms-template-section>
            </div>
        </ng-template>
        <ng-container *ngIf="cdn.isSectionEnabled(content, '4eb6ed87-64e7-425f-a631-f90e523633ba'); else singleCol">
            <div class="col-md-4">
                <cms-template-section id="4eb6ed87-64e7-425f-a631-f90e523633ba"></cms-template-section>
            </div>
            <div class="col-md-8">
                <cms-template-section id="f047bc36-d751-4554-8cf2-f2a6633b8324"></cms-template-section>
            </div>
        </ng-container>
        
    </div>
    
</div>