import { Component, EventEmitter, Injector, Input, Output, Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FORM_COMPLETION_MODEL } from '@murdoughsolutions/cms-client';
import { CDNService, FormCompletionModel } from '@murdoughsolutions/cms-common';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { FormBroker } from 'src/app/brokers/form.broker';
import { component_resolver } from 'src/app/custom-components/component-map';
import { ActionFeedbackModel } from 'src/app/models/action-feedback.model';
import { FormService } from '../../services/form.service';

export class CmsFormParameters {
  public show_submit: boolean;
  public form_group: UntypedFormGroup;

  constructor(show_submit: boolean, form_group: UntypedFormGroup) {
    this.show_submit = show_submit
    this.form_group = form_group
  }
}

interface FormInit {
  component: Type<any>
  injector: Injector
}

@Component({
  selector: 'cms-form',
  templateUrl: './cms-form.component.html',
  styleUrls: ['./cms-form.component.scss']
})
export class CmsFormComponent {

  private readonly remove_fields_subject = new BehaviorSubject<string[]>([])

  @Input() public set removeFields(fields: Array<string>) {
    this.remove_fields_subject.next(fields)
  }

  private readonly disabled_subject = new BehaviorSubject<boolean>(false)

  @Input() public set disable(disabled: boolean) {
    this.disabled_subject.next(disabled)
  }

  private readonly show_submit_subject = new BehaviorSubject<boolean>(true)

  @Input() public set show_submit(show_submit: boolean) {
    this.show_submit_subject.next(show_submit)
  }

  private readonly form_id_subject = new BehaviorSubject<string | undefined>(undefined)
  private readonly form_subject = new BehaviorSubject<FormCompletionModel | undefined>(undefined)

  @Input() public set form(form: FormCompletionModel) {
    this.form_subject.next(form)
  }

  @Input() public set id(form_id: string) {
    this.form_id_subject.next(form_id)
  }

  private readonly form_model$: Observable<FormCompletionModel> = combineLatest([this.form_id_subject, this.form_subject]).pipe(
    switchMap(([form_id, form]) => {
      if (form) {
        return of(form)
      }
      return this.cdn.GetForm(form_id as string)
    }),
    map(form => form as FormCompletionModel),
    shareReplay(1)
  )

  public readonly form$: Observable<FormCompletionModel> = combineLatest([
    this.form_model$,
    this.remove_fields_subject,
    this.disabled_subject
  ]).pipe(
    map(([form, remove_fields, disabled]) => {
      const fields = form.fields.map(field => {
        field.remove = remove_fields.includes(field.mapping) || remove_fields.includes(field.key)
        field.disable = disabled;
        return field
      }).filter(f => !f.remove)

      return {
        ...form,
        fields
      }
    }),
    shareReplay(1)
  )

  private readonly form_group$ = this.form$.pipe(
    map(form => this.service.BuildFormGroup(form.fields)),
    shareReplay(1)
  )

  public readonly init$: Observable<FormInit> = combineLatest([this.form_model$, this.form_group$, this.show_submit_subject]).pipe(
    map(([form, form_group, show_submit]) => {

      const broker = new FormBroker();
      broker.feedbackHandler = this.feedbackHandler;
      broker.submitHandler = this.submitHandler;
      broker.success_message = this.success_message;
      broker.invalid_message = this.invalid_message;

      const component = component_resolver(form.template_id)
      if (!component) {
        throw new Error(`Component not found for Template ID: ${form.template_id}`)
      }

      return {
        injector: Injector.create({
          providers: [
            {
              provide: FORM_COMPLETION_MODEL,
              useValue: form
            },
            {
              provide: FormBroker,
              useValue: broker
            },
            {
              provide: CmsFormParameters,
              useValue: new CmsFormParameters(show_submit, form_group)
            }
          ], parent: this.injector
        }),
        component
      }
    })
  )

  @Input() public feedbackHandler?: EventEmitter<ActionFeedbackModel>;
  @Output() public submitHandler: EventEmitter<FormCompletionModel> = new EventEmitter<FormCompletionModel>();

  @Input() public invalid_message?: string;
  @Input() public success_message?: string;

  constructor(
    private readonly injector: Injector,
    private readonly service: FormService,
    private readonly cdn: CDNService
  ) {

  }

  public getForm(): Observable<FormCompletionModel | undefined> {
    return combineLatest([this.form$, this.form_group$]).pipe(
      map(([form, form_group]) => {
        if (this.service.Validate(form_group, form)) {
          return form
        }
        return undefined
      })
    )
  }

  public reset(): void {
    this.form_group$.pipe(
      take(1)
    ).subscribe(form_group => {
      form_group.reset()
    })
  }

}
