import { EventEmitter } from '@angular/core';
import { ActionFeedbackModel } from '../models/action-feedback.model';
import { FormCompletionModel } from '@murdoughsolutions/cms-common';

export class FormBroker
{
    public feedbackHandler?: EventEmitter<ActionFeedbackModel>;
    public submitHandler: EventEmitter<FormCompletionModel> = new EventEmitter<FormCompletionModel>();
    public success_message?: string;
    public invalid_message?: string;
}
