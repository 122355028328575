import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentResolver } from './resolvers/content.resolver';
import { PostCategoryResolver } from './resolvers/post-category.resolver';
import { PostCollectionResolver } from './resolvers/post-collection.resolver';
import { PostResolver } from './resolvers/post.resolver';

import { CmsFormFieldComponent } from './components/cms-form-field/cms-form-field.component';
import { CmsFormComponent } from './components/cms-form/cms-form.component';
import { CmsLoadingOverlayComponent } from './components/cms-loading-overlay/cms-loading-overlay.component';


import { CmsClientModule } from '@murdoughsolutions/cms-client';
import { CmsCommonModule } from '@murdoughsolutions/cms-common';
import { Angulartics2Module } from 'angulartics2';
import { environment } from 'src/environments/environment';
import { CustomCarousel } from './custom-components/carousel/carousel';
import { CustomContactForm } from './custom-components/contact-form/contact-form';
import { CustomContactPage } from './custom-components/contact-page/contact-page';
import { CustomEMailFormField } from './custom-components/e-mail-form-field/e-mail-form-field';
import { CustomFooterMenu } from './custom-components/footer-menu/footer-menu';
import { CustomHome } from './custom-components/home/home';
import { CustomHoneypot } from './custom-components/honeypot/honeypot';
import { CustomMainMenu } from './custom-components/main-menu/main-menu';
import { CustomPage } from './custom-components/page/page';
import { CustomTextAreaFormField } from './custom-components/text-area-form-field/text-area-form-field';
import { CustomTextFormField } from './custom-components/text-form-field/text-form-field';
import { FormService } from './services/form.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service';
import { MetaService } from './services/meta.service';

const isPrerender = window.navigator.userAgent?.includes('Murdough Solutions CMS')

@NgModule({
  declarations: [
    AppComponent,
    CmsLoadingOverlayComponent,
    CmsFormComponent,
    CmsFormFieldComponent,
    CustomMainMenu, CustomCarousel, CustomTextFormField, CustomEMailFormField, CustomContactPage, CustomHome, CustomPage, CustomContactForm, CustomTextAreaFormField, CustomFooterMenu, CustomHoneypot
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,
    FontAwesomeModule,
    Angulartics2Module.forRoot({
      developerMode: !environment.production || isPrerender
    }),
    CmsCommonModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base,
      cdn_base: environment.cdn_base,
      default_date_format: 'PPP',
      default_date_time_format: 'PPP, p'
    }),
    CmsClientModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base
    }),
    AppRoutingModule,
  ],
  providers: [
    GoogleTagManagerService,
    MetaService,
    FormService,
    ContentResolver,
    PostCollectionResolver,
    PostCategoryResolver,
    PostResolver,
    { 
      provide: NgbDateAdapter, 
      useClass: NgbDateNativeAdapter
    }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

}
