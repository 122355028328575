import { Component } from '@angular/core';
import { LoadingService } from '@murdoughsolutions/cms-common';

@Component({
  selector: 'cms-loading-overlay',
  templateUrl: './cms-loading-overlay.component.html',
  styleUrls: ['./cms-loading-overlay.component.scss']
})
export class CmsLoadingOverlayComponent {

  constructor(public readonly loading: LoadingService) {

  }

}
