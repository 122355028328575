import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CDNService, PostModel } from '@murdoughsolutions/cms-common';
import { Observable } from 'rxjs';

@Injectable()
export class PostResolver  {

    constructor(private cdn: CDNService)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<PostModel> {
        return this.cdn.GetPost(route.data.post_id);
    }
}
